.buy-btn{
    width: 150px;
    background-color: #0099FF;
    color: white;
}
.product-desc span{
    color: #fff !important;
}
.product-desc strong{
    color: #fff !important;
}

.product-slider-img{
    height: 430px;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .product-slider-img{
        height: 330px;
        width: 100%;
    }
    
}

@media screen and (max-width: 550px) {
    .product-slider-img{
        height: 230px;
        width: 100%;
    } 
}