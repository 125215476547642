.NewDetailH1{
    font-size: 50px;
    font-weight: 900;
    line-height: 50px;
    color: white;
  }

  .NewDetailP{
    padding-top: 30px;
    font-size: 20px;
    color:white
}

  @media screen and (max-width: 991px) {
    .NewDetailH1{
        font-size: 40px;
    }
}

@media screen and (max-width: 767px) {
    .NewDetailH1{
        font-size: 35px;
    }
}

@media screen and (max-width: 575px) {
    .NewDetailH1{
        font-size: 30px;
    }
}