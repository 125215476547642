// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


/*================***Mutlipage  Header Section Start Here     ***===================*/

.header-section {
    background: $body-color;
    @extend %w-100;
    z-index: 999;
    position: absolute;

    @include breakpoint(lg) {
        position: initial;
    }

    &.header--fixed {
        @include breakpoint(lg) {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            animation: fadeInDown .5s;
            -webkit-animation: fadeInDown .5s;
            -moz-animation: fadeInDown .5s;
            border-bottom: 1px solid rgba($theme-color, $alpha: .05);
            z-index: 999;

            .header-bottom {
                background: $primary-color;
                backdrop-filter: blur(20.0px);
                -webkit-backdrop-filter: blur(20.0px);
            }
        }

    }
}

.header-wrapper {
    padding: 23px 0;
    @extend %flex;
    @extend %justify-between;
    @extend %align-items-center;

    @include breakpoint(sm) {
        position: relative;
    }

    .logo {
        a {
            @extend %d-block;

            img {
                max-width: 100%;
            }
        }

        @include breakpoint(max-sm) {
            width: 160px;
        }

        @include breakpoint(max-xl) {
            @include breakpoint(lg) {
                width: 200px;
            }
        }
    }

    .menu-area {
        @extend %flex;
        @extend %align-items-center;

        .header-btn {
            @include breakpoint(max-md) {
                display: none;
            }

            @extend %flex;
            @extend %align-items-center;
            column-gap: 20px;


        }
    }
}

.menu {
    @extend %flex,
    %mb-0;

    @include breakpoint(lg) {
        margin-right: 30px;
    }

    >li {
        >a {
            color: $white-color;
            font-size: $fs-base;
            padding: 15px 22px;
            text-transform: uppercase;
            font-family: $title-font;
            font-weight: 400;

            i {
                margin-right: 5px;
            }

            &:after {
                right: 10px;
            }

            &::before {
                right: 14px;
            }

            @include breakpoint(lg) {
                &:after {
                    right: -4px;
                }

                &::before {
                    right: 0;
                }
            }

            @include breakpoint(xl) {
                &:after {
                    right: 4px;
                }

                &::before {
                    right: 8px;
                }
            }
        }
    }

    @include breakpoint(lg) {
        li {
            position: relative;

            .submenu {
                position: absolute;
                top: calc(100%);
                left: 0;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
                z-index: 9;
                background: $primary-color;
                width: 220px;
                padding: 0;
                -webkit-transform: translateY(35px);
                -ms-transform: translateY(35px);
                transform: translateY(35px);
                opacity: 0;
                visibility: hidden;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);

                li {
                    padding: 0;

                    a {
                        padding: 10px 20px;
                        text-transform: uppercase;
                        font-family: $title-font;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: $white-color;
                        border-bottom: 1px solid rgba($theme-color, $alpha: .05);

                        &:hover,
                        &.active {
                            background: $theme-color;
                            padding-left: 22px;
                            color: $black-color;

                            &:after,
                            &:before {
                                background: $black-color;
                            }
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }

                    .submenu {
                        left: 100%;
                        top: 0;
                    }
                }
            }

            &:hover {
                >.submenu {
                    -webkit-transform: translateY(15px);
                    -ms-transform: translateY(15px);
                    transform: translateY(15px);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        @include breakpoint(max-xl) {
            li {
                a {
                    padding: 10px 12px;
                }
            }
        }
    }

    @include breakpoint(max-lg) {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: auto;
        max-height: 400px;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
        -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
        transform: scaleY(0);
        transform-origin: top;

        //glassmorphism
        background: rgba($primary-color, $alpha: .99);
        -webkit-backdrop-filter: blur(20px);

        li {
            width: 100%;
            padding: 0;

            a {
                display: block;
                padding: 10px 25px;
                font-size: 15px;
                text-transform: capitalize;
                border-top: 1px solid rgba($white-color, $alpha: .10);
                color: $white-color;

                &:hover {
                    background: rgba($primary-color, $alpha: .60);
                }
            }

            .submenu {
                padding-left: 20px;
                display: none;

                li {
                    width: 100%;

                    a {
                        font-size: 14px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

        &.active {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
}

.header-bar {
    @extend %p-rel;
    @extend %c-pointer;
    width: 25px;
    height: 20px;
    @extend %ml-30;

    span {
        @extend %p-abs;
        @extend %d-inline-block;
        @extend %w-100;
        height: 3px;
        @include add-prefix(transition, $transition);
        background-color: $white-color;
        left: 0;

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            top: 52%;
            transform: translateY(-65%);
        }

        &:last-child {
            bottom: 0;
        }
    }

    &.active {
        span {
            &:first-child {
                @include add-prefix(transform, rotate(45deg) translate(3px, 9px));
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                @include add-prefix(transform, rotate(-45deg) translate(3px, -9px));
            }
        }
    }

    @include breakpoint(max-sm) {
        width: 15px;
        height: 16px;
        margin-right: 20px;

        span {
            height: 2px;
            width: 20px;
        }

        &.active {
            span {
                &:first-child {
                    @include add-prefix(transform, rotate(45deg) translate(4px, 6px));
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    @include add-prefix(transform, rotate(-45deg) translate(4px, -6px));
                }
            }
        }
    }
}

.menu-item-has-children {


    >a {
        @extend %p-rel;

        &::after {
            @extend %p-abs;
            top: 50%;
            transform: translateY(-50%);
            right: 25px;
            width: 10px;
            height: 2px;
            background-color: $white-color;
        }

        &::before {
            @extend %p-abs;
            top: 50%;
            transform: translateY(-50%);
            right: 29px;
            width: 2px;
            height: 10px;
            background-color: $white-color;
            @include add-prefix(transition, $transition);
        }

        @include breakpoint(lg) {
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 11px;
                width: 10px;
                height: 2px;
                background-color: $white-color;
            }

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                width: 2px;
                height: 10px;
                background-color: $white-color;
                @include add-prefix(transition, $transition);
            }
        }


    }

    &:hover {
        >a {

            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }

    &.open {
        >a {

            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }
}

/*================*** Multipage Header Section End Here     ***===================*/




/*============= wallet modal start=============*/
.wallet-modal {
    background-color: rgba($body-color, $alpha: .80);

    .modal-content {
        @extend %text-center;
        background-color: $primary-color;
    }

    .modal-header {
        @extend %p-rel,
        %justify-center;
        border-bottom: 1px solid rgba($theme-color, $alpha: .3);

        h5 {
            color: $theme-color;
        }

        .btn-close {
            @extend %p-abs;
            top: 0;
            right: 0;
            background-color: $theme-color;
        }
    }

    .modal-body {
        >p {
            &:first-child {
                @include font-size(18px);
                color: $white-color;
            }

        }
    }

    .wallet__list {
        @extend %flex,
        %align-items-center,
        %justify-between;
        margin-block: 30px;

        &-item {
            a {
                @extend %flex,
                %justify-center,
                %text-center,
                %p-10;
                @include add-prefix(transition, $transition);
                flex-direction: column;
                border: 1px solid rgba($theme-color, $alpha: .1);
                border-radius: 5px;

                span {
                    @extend %d-inline-block,
                    %text-center,
                    %of-hidden;
                    @include box(80px);
                    border-radius: 5px;
                }
            }

            &:hover {
                a {
                    border-color: $theme-color;
                }

            }
        }
    }
}

/*============= wallet modal end=============*/






/*=-=-=-======< Section header start here >==========-=-=-=-*/
.section-header {
    @extend %text-center;
    margin-bottom: 40px;

    @include breakpoint(lg) {
        margin-bottom: 60px;
    }

    h2 {
        @extend %mb-15;
    }
}

/*=-=-=-======< Section header end here >==========-=-=-=-*/




/*=-=-=-======< Page header start here >==========-=-=-=-*/
.page-header {
    padding-block: 70px;
    background-size: cover;
    background-position: center;

    @include breakpoint(lg) {
        padding-block: 100px;
    }

    @include breakpoint(xxl) {
        padding-block: 120px;
    }

    .breadcrumb-item {

        &:before,
        &.active {
            color: $white-color;
        }

    }
}

/*=-=-=-======< Page header end here >==========-=-=-=-*/