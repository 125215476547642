/* ants_nav */
:root {
    --rounded-theme: 0.5rem;
}

.theme-color {
    color: #0099ff !important;
}

/* hero__section */
.hero__section {
    position: relative;
    opacity: 1;
    /* z-index: 10; */
}

.ants-token-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* section__padding */
.section__padding {
    padding: 5rem 0;
}

/*  */
.ants_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* round-theme */
.round-theme {
    border-radius: 0.5rem !important;
}

/* ants_nav */
.benifits__row,
.projects-row,
.pricings-row {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.pricings-row-start {
    max-width: 900px;
    width: 100%;
    margin: 0;
}

.benifits__row {
    row-gap: 1rem;
}

.input-theme {
    background: #111111 !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
    padding: 0.9rem 1rem !important;
    color: #fff !important;
}

.input-theme::placeholder {
    color: #979797 !important;
}

.input-theme:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #2caaf7 !important;
    /* background: transparent !important; */
}

/* project-detail-img */
.project-detail-img img {
    width: 100%;
    height: 35vh;
    border-radius: 0.5rem;
    object-fit: cover;
}

/* badges__item__grid */
.project_detail_donations {
    row-gap: 1.7rem;
}

/* billing__category__wrapper */
.project_detail_donations_wrapper {
    /* max-width: 900px; */
    width: 100%;
    display: grid;
    align-items: center;
    gap: 0.5rem;
}

.project_detail_donations_wrapper .project_detail_donations_item {
    border: 1px solid #048be4;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.05rem rgb(4, 139, 228);
    display: grid;
    gap: 0.3rem;
    align-items: center;
    text-align: center;
    justify-items: center;
    padding: 0.3rem 1rem;
}

.project_detail_donations_wrapper_card {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 0.9rem;
}

.project_detail_donations_wrapper_card .project_detail_donations_item_card {
    border: 1px solid #048be4;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.05rem rgb(4, 139, 228);
    display: grid;
    gap: 0.3rem;
    align-items: center;
    text-align: center;
    justify-items: center;
    padding: 0.3rem 0.7rem;
}

.project_detail_donations_card {
    row-gap: 0.6rem;
    padding-right: 2px;
    padding-left: 2px;
}
.project_detail_donations_item_card h6 {
    font-size: 0.6rem;
}

.project_detail_donations_item_card h3 {
    font-size: 0.9rem;
}   

/* see_more_btn */
@media screen and (max-width: 991px) {
    .pricings-row-start {
        max-width: none;
        width: 100%;
        margin: 0;
        row-gap: 3rem;
    }

    .pricings-row {
        max-width: none;
        width: 100%;
        row-gap: 2rem;
    }

    .projects-row {
        width: 100%;
        row-gap: 1rem;
    }

    .page-header__content h2 {
        font-size: 1rem;
    }

    .project-detail-img img {
        height: 27vh;
    }

    .ourmission img {
        width: 100%;
        height: 45vh;
        object-fit: cover;
    }

    .user__nav__img img {
        width: 41px !important;
        height: 41px !important;
        opacity: 1;
        z-index: 500;
        position: relative;
    }

    .project_detail_donations_wrapper {
        max-width: none;
        width: 100%;
    }
    .project_detail_donations_wrapper_card {
        max-width: none;
        width: 100%;
    }

    .project_detail_donations_item {
        padding: 1.1rem 0.4rem !important;
    }
    .project_detail_donations_item_card {
        padding: 1.1rem 0.4rem !important;
    }
    
}

@media screen and (max-width: 767px) {
    .project-detail-text h2 {
        font-size: 1.3rem !important;
    }

    .section__padding {
        padding: 3rem 0;
    }

    .project_detail_donations_item h6 {
        font-size: 1rem;
    }

    .project_detail_donations_item h3 {
        font-size: 1.3rem;
    }
    .project_detail_donations_item_card h6 {
        font-size: 0.5rem;
    }

    .project_detail_donations_item_card h3 {
        font-size: 0.7rem;
    }
}