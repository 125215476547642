.banner {
  @extend %of-hidden;
  @extend %p-rel;
  @include background-cover;
  padding-block: 160px;



  @include breakpoint(md) {
    padding-block-start: 230px;
    padding-block-end: 200px;
  }

  @include breakpoint(lg) {
    padding-block: 180px;
  }

  @include breakpoint(xxl) {
    padding-block: 225px;
  }

  &--2 {
    padding-block-start: 160px;
    padding-block-end: 90px;

    @include breakpoint(md) {
      padding-block-start: 200px;
      padding-block-end: 90px;
    }

    @include breakpoint(lg) {
      padding-block: 100px;
    }
  }


  &__content {
    @extend %p-rel,
    %mb-50;
    z-index: 1;


    @include breakpoint(lg) {
      // max-inline-size: 65ch;
      margin-bottom: 0;
    }

    h1 {
      @extend %mb-20;
      text-transform: uppercase;

      @include breakpoint(lg) {
        @include breakpoint(max-xxl) {
          @include font-size(65px);
        }
      }

      @include breakpoint(xxl) {
        max-inline-size: 90%;
      }



    }

    h1,
    p {
      color: $white-color;
    }

    p {
      @extend %mb-45;
      @include font-size(18px);
      font-family: $title-font;
      line-height: 1.65;
      max-inline-size: 42ch;
    }


  }

  &__thumb {
    @extend %p-rel;

    .banner-shape {
      @extend %p-abs;
      @include add-prefix(transform, translateX(-50%));
      top: -40px;
      left: 45%;
      animation: zoom 3s linear infinite alternate;
    }

    gap: 10px;

    &-slider-2 {
      @extend %of-hidden,
      %m-0;
      height: 350px;

      @include breakpoint(sm) {
        height: 520px;
      }

      @include breakpoint(md) {
        height: 630px;
      }

      @include breakpoint(lg) {
        height: 450px;
      }

      @include breakpoint(xl) {
        height: 540px;
      }

      @include breakpoint(xxl) {
        height: 620px;
      }
    }

    &-slider-1,
    &-slider-3 {
      @extend %of-hidden,
      %m-0;
      height: 230px;

      @include breakpoint(sm) {
        height: 345px;
      }

      @include breakpoint(md) {
        height: 420px;
      }

      @include breakpoint(lg) {
        height: 300px;
      }

      @include breakpoint(xl) {
        height: 350px;
      }

      @include breakpoint(xxl) {
        height: 410px;
      }
    }

    &-item {
      @extend %of-hidden;

      img {
        @extend %w-100;
      }
    }
  }



  &__video {
    @extend %p-abs;
    left: -10px;
    top: -30px;

    @include breakpoint(md) {
      left: -20px;
      top: -50px;
    }

    &-inner {
      border: 5px solid $body-color;
      border-radius: 50%;
      @extend %p-rel;


      >svg {
        @extend %p-5;
        @include box(70px);
        background-color: $secondary-color;
        border-radius: 50%;
        animation: rotate 6s linear infinite normal;

        @include breakpoint(md) {
          @include box(90px);
        }

        @include breakpoint(xxl) {
          @include box(110px);
        }

        text {
          @include font-size(10px);
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      span {
        @extend %p-abs;
        @include add-prefix(transform, translate(-50%, -50%));
        top: 50%;
        left: 50%;

        svg {
          color: $black-color;
          @include font-size(20px);
        }
      }
    }

    &:hover {
      .banner__video-inner {
        >svg {
          animation-play-state: paused;
        }
      }
    }
  }
}

.btn-group {
  @extend %flex,
  %align-items-center;
  gap: 25px;
}

.bg-attach--fixed {
  @include breakpoint(lg) {
    background-attachment: fixed;
  }

}

.bg--home2 {
  position: fixed;
  background-image: url(../images/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}