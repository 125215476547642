.category:hover{
    cursor: pointer;
}
.subcategory:hover{
    cursor: pointer;
    color: #0099FF !important;
}

.pagination-btn{
    background-color: #0099FF;
    color: white;
}