.stripepayment-overlay {
  background: rgb(1, 10, 64, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(0.2rem) saturate(100%);
  animation: 0.7s ease 0s 1 normal forwards running animateOverlay;
}

.stripepayment-modal {
  opacity: 1;
  z-index: 50;
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 2rem 2rem 1.5rem 2rem;
  border-radius: 1rem;
  position: relative;
  background: rgb(0, 0, 0);
  backdrop-filter: blur(40px);
  border: 1px dashed rgba(255, 255, 255, 0.2);
  box-shadow: rgba(255, 255, 255, 0.3) 0px 10px 40px,
    rgb(0, 0, 0) 0px 0px 0px 1px inset;
  animation: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards
    running animateModel;
}
.stripepayment-modal::-webkit-scrollbar {
  display: none;
}

.stripe-close-icon {
  position: absolute;
  top: -1vh;
  right: -0.5vw;
  cursor: pointer;
  opacity: 1;
  z-index: 1;
}
/* stripe-input-theme */
.stripe-input-theme {
    background: #111111 !important;
    border-color: rgba(255,255,255,0.35) !important;
    border-style: dashed !important;
    padding: 0.9rem 1rem !important;
    color: #fff !important;
}
.stripe-input-theme::placeholder {
    color: #979797 !important;
}
.stripe-input-theme:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #2caaf7 !important;
    /* background: transparent !important; */
}
@keyframes animateOverlay {
  0% {
    backdrop-filter: blur(0) saturate(100%);
  }
  100% {
    backdrop-filter: blur(0.2rem) saturate(120%);
  }
}

@keyframes animateModel {
  0% {
    transform: translateX(0px) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateX(0px) scale(1);
    opacity: 1;
  }
}
