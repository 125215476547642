.counter {
    &--uplifted {
        @extend %p-rel;
        z-index: 1;
        margin-top: -80px;

    }

    &__item {
        background-color: $black-color;
        padding-block: 40px;

        &-content {
            @extend %text-center;

            h2 {
                color: $theme-color;
                margin-top: -10px;
            }

            p {
                @extend %mb-0;
                color: $white-color;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }

    // .col-lg-3 {
    //     &:not(:last-child) {
    //         border-right: 1px solid $body-color;
    //     }
    // }
}