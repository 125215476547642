.profile__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.profile__info {
  display: grid;
  align-items: center;
}
.profile__wrapper .user_profile_img {
  width: 155px;
  height: 155px;
  border-radius: 100%;
  object-fit: cover;
}

.buttons__nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 4vh 0 3vh 0;
  gap: 1rem;
}
.buttons__nav .button__div {
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
}
.buttons__nav button {
  outline: none;
  cursor: pointer;
  padding: 0.55rem 1rem;
  border-radius: 0.4rem;
  background: transparent;
  border: 1px solid #2eabff;
  opacity: 1;
  z-index: 50;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}
.buttons__nav .activeMenuBtns {
  background: #2eabff;
  color: white;
}
.buttons__nav .activeMenuBtns:hover {
  background: #048be4;
}
.buttons__nav .outlineMenuBtns {
  color: #2eabff;
}
.buttons__nav .outlineMenuBtns:hover {
  background: #2eabff;
  color: #fff;
}

/* profile__edit__wrapper */
.profile__edit__wrapper {
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem 0.7rem 0.7rem 0.7rem;
  border-radius: 0.5rem;
  max-width: 235px;
  display: grid;
  align-items: center;
  gap: 0.7rem;
}
.profile__edit__wrapper img {
  width: 100%;
  height: 21vh;
  object-fit: cover;
  border-radius: 0.7rem;
}

/* profile__object__wrapper */
.profile__object__wrapper {
  position: absolute;
  top: 6vh;
  right: 0rem;
  height: auto;
  width: 10rem;
  padding: 0 0;
  background: rgb(0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  opacity: 1;
  z-index: 700;
}
.profile__wrapper__content {
  padding: 0.7rem 1rem;
  display: grid;
  align-items: center;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  width: 100%;
  border-radius: 0.5em 0.5rem;
}
.profile__wrapper__content:hover {
  background: #2eabff;
}
.profile__wrapper__content .my__profile {
  cursor: pointer;
}
.profile__wrapper__content .logout__button {
  cursor: pointer;
}
.profile__wrapper__content:hover .my__profile,
.profile__wrapper__content:hover .logout__button {
  color: #fff;
}

/* profile_info_content */
.profile_info_content {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

/* billing__category__wrapper */
.billing__category__wrapper {
  /* max-width: 900px; */
  width: 100%;
  display: grid;
  align-items: center;
  gap: 1rem;
}
.billing__category__wrapper .billing__category__item {
  border: 1px solid #048be4;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.05rem rgb(4, 139, 228);
  display: grid;
  gap: 1rem;
  align-items: center;
  text-align: center;
  justify-items: center;
  padding: 1.5rem 1rem;
}

/* badges__item__grid */
.billings__category__row {
  row-gap: 1.7rem;
}
.badges__item__grid {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.badges__item__grid .badges__item {
  border: 1px solid #048be4;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.05rem rgb(4, 139, 228);
  display: grid;
  gap: 0.7rem;
  align-items: center;
  text-align: center;
  justify-items: center;
  padding: 0.7rem 1rem;
}

@media screen and (max-width: 991px) {
  .edit__form__row {
    flex-direction: column-reverse !important;
    row-gap: 2rem !important;
  }
  .billing__category__wrapper {
    max-width: none;
    width: 100%;
  }
  .billing__category__item {
    padding: 1.5rem 0.5rem !important;
  }
}

@media screen and (max-width: 767px) {
  .profile__wrapper_row {
    row-gap: 2rem;
  }
  .profile__wrapper {
    gap: 1.5rem;
  }
  .profile__wrapper .user_profile_img {
    width: 130px;
    height: 130px;
  }
  .profile__info {
    gap: 0.7rem;
    margin-top: 0;
  }
  .profile__update__button {
    line-height: normal;
    padding: 0.6rem 1rem;
  }
  .billing__category__item h6 {
    font-size: 1rem;
  }
  .billing__category__item h3 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 550px) {
  .profile__wrapper_row {
    row-gap: 0;
  }
  .profile__wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
  .profile__wrapper .user_profile_img {
    width: 130px;
    height: 130px;
    border-radius: 0.5rem;
  }
  .profile__update__button {
    position: absolute;
    top: 5.7vh;
    right: 3rem;
    line-height: normal;
    padding: 0.6rem 1rem;
  }
}
@media screen and (max-width: 475px) {
  .profile__update__button {
    position: absolute;
    top: 5.7vh;
    right: 1rem;
  }
}
