.user__item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    background: #000000;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0,0,0,1);
}
.user__item__img {
    overflow: hidden;
    border-radius: 0.5rem;
    width: 50%;
    height: auto;
    flex: 0 1 auto;
}
.user__item__text {
    width: 50%;
    height: auto;
    flex: 0 1 auto;
    padding: 1rem 1rem 1rem 0;
    display: grid;
    align-items: center;
}
.user__item__img img {
    width: 100%;
    height: 19vh;
    object-fit: cover;
}

.user__item:hover .user__item__img img{
    transform: scale(1.1);
    transition: all 0.5s ease-in-out 0s;
}


@media screen and (max-width: 1500px) {
    .user__item__text h4 {
        font-size: 1rem;
    }
}